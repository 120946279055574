@import "../../global.scss";

.intro {
    background-color: white;
    display: flex;
    //background: linear-gradient(to bottom right, #fff 0%, #fff 50%, $mainColor 50%, $mainColor 100%);
    clip-path: polygon(1% 0, 100% 0%, 0 100%, 34% 100%);

    @include mobile {
        flex-direction: column;
        align-items: center;
    }


    .left {
        flex: 0.2;
        overflow: hidden;
        
        .imgContainer {
            background-color: crimson;
            border-radius: 50%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            float: right;

            @include mobile{
                align-items: flex-start;
            }

            img {
                height: 90%;

                @include mobile {
                    height: 50%;
                }
            }
        }
    }

    .right {
        flex: 0.8;

        .wrapper {
            width: 100%;
            height: 80%;
            padding-left: 10px;
            
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include mobile {
                padding-left: 0px;
                align-items: center;
            }

            h1 {
                font-size: 60px;
                margin: 10px 0;

                @include mobile {
                    font-size: 40px;
                }
            }

            h2 {
                font-size: 35px;
            }

            h3 {
                font-size: 30px;

                @include mobile {
                    font-size: 20px;
                }

                span {
                    font-size: inherit;
                    color: crimson;
                }

                .ityped-cursor {
                    animation: blink 1s infinite
                }

                @keyframes blink {
                    50% {
                        opacity: 1;
                    }
                    100% {
                        opacity: 0;
                    }
                }
            }
        }
        
        a {
            position: absolute;
            bottom: 10px;
            left: 70%;

            img {
                width: 30px;
                animation: arrowBlink 2s infinite;
            }

            @keyframes arrowBlink {
                100% {
                    opacity: 0;
                }
            }
        }
    }
}